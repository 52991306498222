// React
import * as React from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

// Third Party
import { Box, Drawer, List, ListItemIcon, ListItemText, ListSubheader, Typography, Divider, ListItemButton, Chip, Tooltip } from '@mui/material';
import { SpeedOutlined, QuizOutlined, DifferenceOutlined, VerifiedUserOutlined, AdminPanelSettingsOutlined, WidgetsOutlined, BalanceOutlined, WorkHistoryOutlined, HomeOutlined, LogoutOutlined, CloudQueueOutlined, NotificationImportantOutlined, CloudSyncOutlined, ListAltOutlined } from '@mui/icons-material';
import moment from 'moment';
/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';

// CSS
import './Sidebar.css';

// Image
import logo from './logo.png';

// Context
import GlobalAuthenticatedContext from 'common/contexts/GlobalAuthenticatedContext';
import GlobalAuthenticatedContextModel from 'common/contexts/GlobalAuthenticatedContextModel';

// Components
import OrganizationSidebar from './OrganizationSidebar';
import CXAsCodeSidebar from './CXAsCodeSidebar';

// Services
import { deleteAuthenticate, getUsersGrants } from 'services/api/authService';
import { getJobs } from 'services/api/jobService';
import { getSelf } from 'services/api/gc/userService';
import { getAFOrganization, getOrgMe } from 'services/api/gc/organizationService';
import { getActivityMe, postActivity } from 'services/api/activityService';
import { getConfiguration } from 'services/api/configService';
import { postCXModuleValidate } from 'services/api/modulesService';
import { getSubscriptions } from 'services/api/subscriptionsService';

// Utilities
import { AuthenticationConstants, OrganizationConstants, JobsConstants } from 'common/utilities/appConstants';
import { removeStorageItem, getStorageItem, setStorageItem } from 'common/utilities/applicationStorage';
import { downloadPreSignedUrl } from 'common/utilities/utilities';

// Configuration
import config from '../../../config';

// Hooks
import useNotification from 'common/hooks/useNotification';
import { getUserAudit } from 'services/api/auditService';

const drawerWidth = 325;

const flickerStyle = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0.5;
    }
`;

const Sidebar = ({ jobsUpdates, setJobsUpdates }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { globalAuthenticated, setGlobalAuthenticated } = React.useContext(GlobalAuthenticatedContext);
    const [setNotification] = useNotification();

    const [org, setOrg] = React.useState({});
    const [region, setRegion] = React.useState('');
    const [isBCPActive, setIsBCPActive] = React.useState(false);
    const [isGCAActive, setIsGCAActive] = React.useState(false);

    const [loggingOutUser, setLoggingOutUser] = React.useState(false);

    const [jobAggregate, setJobAggregate] = React.useState([]);
    const [jobCheckFetching, setJobCheckFetching] = React.useState(false);
    const [jobStatus, setJobStatus] = React.useState('');
    const [jobCount, setJobCount] = React.useState(0);
    const [newJobsAdded, setNewJobsAdded] = React.useState(false);
    const [newJobsNotification, setNewJobsNotification] = React.useState(false);

    const [error, setError] = React.useState(null);
    const [forbidden, setForbidden] = React.useState(false);

    const fetchUser = async () => {
        var redirectToLogin = false;
        try {
            if (globalAuthenticated && !globalAuthenticated.contextLoaded) {
                const inGCloudCheck = getStorageItem(OrganizationConstants.UrlPromptLogin, undefined, undefined, sessionStorage);

                console.log('Attempting to update the user activity record.');
                try {
                    await postActivity();
                    console.log('User activity record updated.');
                } catch (error) {
                    console.error(error);
                    console.log('User activity record did not update.');
                    // letting the application decide next course of action, no subscription, appfoundry, etc. depending on env. and appfoundry allowed environments.
                }

                const { data: configuration } = await getConfiguration();
                const { data: user } = await getSelf();
                const { data: userActivity } = await getActivityMe();
                const { data: org } = await getOrgMe();
                const { data: orgGrants } = await getUsersGrants();

                const bypassEnvs = config && config.app && config.app.bypassEnvs ? Array.from(config.app.bypassEnvs) : Array.from(['local', 'development', 'sandbox']);
                const allowInternalSubEnvs = config && config.app && config.app.internalAllowedEnvs ? Array.from(config.app.internalAllowedEnvs) : Array.from(['test', 'production']);
                const allowedAfEnvs = config && config.app && config.app.afAllowedEnvs ? Array.from(config.app.afAllowedEnvs) : Array.from(['production']);
                const envFromSession = getStorageItem(OrganizationConstants.UrlAuthShort, undefined, undefined, sessionStorage);
                const isGenesysEmployee = user && user.data.email.indexOf('@genesys.com') > -1;

                let bypassResult = false;
                let internalSubResult = false;
                let internalSubscription = {};
                let internalSubResultModules = [];
                let internalSubResultError = false;
                let afResult = false;
                let afResultRecord = Object.assign({}, {});
                let navigateToAF = false;
                let currentEnv = '';

                if (process.env.REACT_APP_CUSTOM_ENV) {
                    currentEnv = process.env.REACT_APP_CUSTOM_ENV;
                }

                if (currentEnv && currentEnv.length > 0 && bypassEnvs.includes(currentEnv)) {
                    console.log('Found Bypass Env: ...');
                    bypassResult = true;
                } else {
                    // internal sub check
                    if (currentEnv && currentEnv.length > 0 && allowInternalSubEnvs.includes(currentEnv)) {
                        console.log('Internal Subscription Process Starting: ...');
                        const { data: internalSub } = await getSubscriptions();
                        const loggedInOrg = org.data.name;
                        const loggedInOrgIdentifier = org.data.id;

                        if (internalSub && internalSub.subscriptions && internalSub.subscriptions.length > 0) {
                            const isOneThere = internalSub.subscriptions.find((f) => f.orgId === loggedInOrgIdentifier);
                            if (isOneThere) {
                                const isEnvCorrect = isOneThere.environment === envFromSession;
                                const isDateValid = moment(isOneThere.subscriptionEndDate).isValid() && moment(isOneThere.subscriptionEndDate).isSameOrAfter(moment().utc());
                                if (isEnvCorrect && isDateValid) {
                                    internalSubResult = true;
                                    internalSubResultModules = Array.from(isOneThere.modules);
                                } else {
                                    internalSubResultError = true;
                                }
                                internalSubscription = isOneThere;
                            } else {
                                // internal subs present but did not find one for this organization
                                internalSubResult = false;
                                internalSubResultError = false;
                            }
                        } else {
                            internalSubResult = false;
                            internalSubResultError = false;
                        }
                    } else {
                        internalSubResult = false;
                        internalSubResultError = false;
                        internalSubResultModules = [];
                    }

                    // app foundry check
                    if (currentEnv && currentEnv.length > 0 && allowedAfEnvs.includes(currentEnv)) {
                        console.log('AppFoundry Process Starting: ...');
                        const checkAFSubscription = async () => {
                            try {
                                // pull the AF subscriptions
                                const { data: afOrg } = await getAFOrganization();
                                afResult = true;
                                navigateToAF = false;
                                afResultRecord = Object.assign({}, { ...afOrg });
                            } catch ({ data, success, status, error }) {
                                if (!success && status >= 400) {
                                    if (status === 403) setForbidden(true);
                                    if (status === 404) {
                                        // AF did not have a subscription in the table, therefore we need to deny access to the application.
                                        afResult = false;
                                        navigateToAF = true;
                                    } else setError(data);
                                }
                            }
                        };

                        await checkAFSubscription();
                    } else {
                        afResult = false;
                        navigateToAF = false;
                    }

                    if (internalSubResult && afResult && isGenesysEmployee) {
                        // internal subscription and app foundry subscription found, internal employee is super user
                        afResult = false;
                        navigateToAF = false;
                        afResultRecord = Object.assign({}, {});
                    } else if (internalSubResult && afResult && !isGenesysEmployee) {
                        // internal subscription and app foundry subscription found, customer found
                        internalSubResult = false;
                        internalSubscription = {};
                        internalSubResultError = false;
                        internalSubResultModules = [];
                    }
                }

                const adminAvailable = configuration.admins.filter((f) => f.user === user.data.email);

                globalAuthenticated.configuration = { ...configuration };
                globalAuthenticated.admins = Array.from([...configuration.admins]);
                globalAuthenticated.modules = Array.from([...configuration.modules]);
                globalAuthenticated.user = { ...user.data };
                globalAuthenticated.activity = { ...userActivity };
                globalAuthenticated.genesys = { ...org.data };
                globalAuthenticated.grants = { ...orgGrants };

                globalAuthenticated.sessionLoaded = true;
                globalAuthenticated.userLoaded = true;
                globalAuthenticated.contextLoaded = true;
                globalAuthenticated.inGCloud = inGCloudCheck === '';

                globalAuthenticated.bypass = bypassResult;

                globalAuthenticated.internalSubscription = internalSubResult;
                globalAuthenticated.subscription = internalSubscription;
                globalAuthenticated.internalSubscriptionError = internalSubResultError;
                globalAuthenticated.activeModules = Array.from([...internalSubResultModules]);

                globalAuthenticated.appFoundry = afResult;
                globalAuthenticated.appFoundryRecord = afResultRecord;
                globalAuthenticated.navigateToAF = navigateToAF;

                globalAuthenticated.isAdmin = adminAvailable && adminAvailable.length > 0;
                globalAuthenticated.isGenesysEmployee = isGenesysEmployee;

                // console.log(globalAuthenticated);
                setGlobalAuthenticated(Object.assign({}, globalAuthenticated));
            }
        } catch ({ data, success, status, error }) {
            if (data && data.error && data.error.indexOf('Session not found') >= 0) {
                console.log('User session expired, navigating to login.');
                redirectToLogin = true;
            }
        } finally {
            if (redirectToLogin) {
                navigateToLogin();
                return;
            }
            if (!globalAuthenticated.appFoundry && globalAuthenticated.navigateToAF) {
                navigate('/appfoundry');
                return;
            }
            if (globalAuthenticated.internalSubscriptionError && !globalAuthenticated.bypass && !globalAuthenticated.appFoundry) {
                navigate('/issue/internal-subscription');
                return;
            }
            if (!globalAuthenticated.internalSubscription && !globalAuthenticated.bypass && !globalAuthenticated.appFoundry && !globalAuthenticated.navigateToAF && !globalAuthenticated.internalSubscriptionError) {
                navigate('/issue/no-environment-found');
                return;
            }
        }
    };

    const fetchValidate = async () => {
        var redirectToLogin = false;
        try {
            if (globalAuthenticated && !globalAuthenticated.contextLoaded) {
                const { data: validate } = await postCXModuleValidate();
            }
        } catch ({ data, success, status, error }) {
            if (data && data.error && data.error.indexOf('Session not found') >= 0) {
                console.log('User session expired, navigating to login.');
                redirectToLogin = true;
            } else if (!success && status >= 400) {
                if (status === 403) {
                    setForbidden(true);
                }
                if (data.code === 'bad.client.role') {
                    return;
                } else {
                    setError(data);
                }
            }
        } finally {
            if (redirectToLogin) {
                navigateToLogin();
                return;
            }
        }
    };

    const fetchJobsAndCheck = async () => {
        setJobCheckFetching(true);
        setNewJobsAdded(false);
        try {
            const authenticated = getStorageItem(AuthenticationConstants.Key, undefined, undefined, sessionStorage);
            if (!authenticated) {
                return;
            }
            const { data } = await getJobs();
            const dateOfLastVisit = getStorageItem(JobsConstants.Visit, undefined, undefined, sessionStorage);
            const dateOfLastVisitMoment = moment(dateOfLastVisit);
            const aggregatedData = [];
            setJobsUpdates(Array.from(data));

            const jobs = Array.from(data);
            jobs.forEach((f) => {
                const dateOfJob = moment(f.dateFinished);
                let obj = {
                    id: f.jobId,
                    new: dateOfLastVisit === null || dateOfLastVisit === undefined ? true : dateOfJob.isAfter(dateOfLastVisitMoment),
                    complete: f.status === 'completed' || f.status === 'failed',
                };
                aggregatedData.push(obj);
            });

            setJobAggregate([...aggregatedData]);

            const isAnythingNew = aggregatedData.filter((f) => f.new && f.complete).length > 0;
            if (isAnythingNew) {
                const hasMessageBeenSent = getStorageItem(JobsConstants.MessageSent, undefined, undefined, sessionStorage);
                if (hasMessageBeenSent === null || !hasMessageBeenSent) {
                    setStorageItem(JobsConstants.MessageSent, true, undefined, undefined, sessionStorage);
                    setNotification({
                        msg: 'Visit the Jobs page (top of page) to download a newly completed job.',
                        variant: 'info',
                        duration: 10000,
                    });
                }
            }

            const jobsCompleted = aggregatedData.filter((f) => f.complete).length;
            const jobsNew = aggregatedData.filter((f) => f.new).length;
            const jobsInProgress = aggregatedData.filter((f) => !f.complete).length;

            if (jobsNew > 0) {
                setNewJobsNotification(true);
            } else {
                setNewJobsNotification(false);
            }

            setJobCount(data.length);
            setJobStatus(`New: ${jobsNew}, Completed: ${jobsCompleted}, In-Progress: ${jobsInProgress}`);
        } catch ({ data, status, success }) {
            if (status === 401) {
                logout(true);
            } else if (data && data.error && data.error.indexOf('Session not found') >= 0) {
                console.log('User session expired, navigating to login.');
                logout(true);
            }
        } finally {
            setJobCheckFetching(false);
        }
    };

    const navigateToLogin = () => {
        if (window.location.href.indexOf('/login') <= 0) {
            navigate('/login?type=code');
        }
    };

    React.useEffect(() => {
        const jobInterval = setInterval(() => {
            if (globalAuthenticated && globalAuthenticated.contextLoaded) {
                fetchJobsAndCheck();
            }
        }, 60000);

        if (globalAuthenticated && !globalAuthenticated.contextLoaded) {
            clearInterval(jobInterval);
        }

        return () => {
            clearInterval(jobInterval);
        };
    }, [globalAuthenticated, globalAuthenticated.contextLoaded]);

    React.useEffect(() => {
        if (globalAuthenticated && !globalAuthenticated.contextLoaded) {
            if (!loggingOutUser) {
                const previousAuthenticated = getStorageItem(AuthenticationConstants.Key, undefined, undefined, sessionStorage);
                if (previousAuthenticated) {
                    console.log('User authenticated.');
                    fetchUser();
                    fetchValidate();
                } else {
                    console.log('User unauthenticated, navigating to login.');
                    if (window.location.href.indexOf('/login') <= 0) {
                        navigate('/login?type=code');
                    }
                }
            }
        } else if (globalAuthenticated && globalAuthenticated.contextLoaded) {
            let resultBCP = false;
            let resultGCA = false;

            setOrg(globalAuthenticated.genesys);

            if (globalAuthenticated.activity && globalAuthenticated.activity.organizations) {
                let foundOrg = globalAuthenticated.activity.organizations.find((o) => o.organizationId == globalAuthenticated.genesys.id);
                if (foundOrg) {
                    setRegion(foundOrg.region);
                }
            }

            if (globalAuthenticated.bypass) {
                resultBCP = true;
                resultGCA = true;
            } else {
                if (globalAuthenticated.internalSubscription && globalAuthenticated.activeModules && globalAuthenticated.activeModules.length > 0) {
                    const isBCPThere = globalAuthenticated.activeModules.find((f) => f.moduleName === 'ignite-bcp');
                    if (isBCPThere) resultBCP = true;

                    const isGCAThere = globalAuthenticated.activeModules.find((f) => f.moduleName === 'ignite-gca');
                    if (isGCAThere) resultGCA = true;
                }

                if (globalAuthenticated.appFoundry) {
                    resultBCP = true;
                    resultGCA = false;
                }
            }

            setIsBCPActive(resultBCP);
            setIsGCAActive(resultGCA);
        }

        return () => {};
    }, [globalAuthenticated, globalAuthenticated.internalSubscription, globalAuthenticated.activeModules, globalAuthenticated.bypass, globalAuthenticated.appFoundry]);

    const getAppVersion = React.useCallback(() => {
        let result = '';
        if (process.env.REACT_APP_BUILD_VERSION) {
            result = process.env.REACT_APP_BUILD_VERSION;
        } else {
            result = 'Local Build';
        }
        return result;
    }, [globalAuthenticated]);

    const getEnvDisplay = React.useCallback(() => {
        let result = '';
        if (globalAuthenticated) {
            if (globalAuthenticated.internalSubscription) {
                result = 'Subscription';
            } else if (globalAuthenticated.appFoundry) {
                result = 'AppFoundry Subscription';
            } else if (globalAuthenticated.bypass) {
                result = 'Bypass Subscription';
            }
        }
        return result;
    }, [globalAuthenticated, globalAuthenticated.internalSubscription, globalAuthenticated.appFoundry, globalAuthenticated.bypass]);

    const navigateToHome = React.useCallback(() => {
        navigate('/');
    }, []);

    const logout = React.useCallback(async (skipSessionCall = false) => {
        setLoggingOutUser(true);
        try {
            console.log('Session Ending');
            if (!skipSessionCall) {
                const { data } = await deleteAuthenticate();
            }
            console.log('Session Destroyed');

            setGlobalAuthenticated(Object.assign({}, { ...GlobalAuthenticatedContextModel }));
            removeStorageItem(AuthenticationConstants.Key, undefined, undefined, sessionStorage);

            const queryStringData = {
                client_id: config.purecloud.client,
                redirect_uri: `${window.location.protocol}//${window.location.host}`,
            };
            const params = Object.keys(queryStringData)
                .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(queryStringData[k])}`)
                .join('&');
            const envFromSession = getStorageItem(OrganizationConstants.UrlAuth, undefined, undefined, sessionStorage);
            const envFromSessionSanitized = typeof envFromSession === 'string' && envFromSession.trim().length > 0 ? envFromSession.trim() : '';
            const href = `${envFromSessionSanitized}/logout?${params}`;
            console.log('Logging Out via: %o', href);
            window.location = href;
        } catch ({ data, success, error }) {
        } finally {
            setLoggingOutUser(false);
        }
    }, []);

    const handleGuideDownload = React.useCallback(() => {
        if (isBCPActive) {
            downloadPreSignedUrl(`${process.env.PUBLIC_URL}/resources/docs/module/bcp.pdf`, 'BCP Wizard Technical Document');
        }

        if (isGCAActive) {
            downloadPreSignedUrl(`${process.env.PUBLIC_URL}/resources/docs/module/accelerator.pdf`, 'GC Accelerator Technical Document');
        }
    }, []);

    return (
        <React.Fragment>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: '#23395d' },
                }}
                className="ignite-sidebar">
                {/* <Toolbar /> */}
                {globalAuthenticated && globalAuthenticated.contextLoaded && (
                    <React.Fragment>
                        <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
                            <Box sx={{ display: 'flex' }}>
                                <img className="ignite-sidebar-img" src={`${logo}`} />
                            </Box>
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ mt: '2px', mb: '0px', borderColor: 'white', mr: '10px', ml: '5px' }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h5">{globalAuthenticated.subscription && globalAuthenticated.subscription.customerName ? globalAuthenticated.subscription.customerName : 'Genesys'}</Typography>
                                <Typography variant="subtitle2">{globalAuthenticated.user.name}</Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        mt: 0.2,
                                        justifyContent: 'flex-start',
                                        flexWrap: 'wrap',
                                    }}>
                                    <Chip sx={{ color: 'white' }} icon={<HomeOutlined color="white" sx={{ ml: '12px !important' }} />} onClick={navigateToHome} variant="outlined" label="Home" size="small" />
                                    <Chip sx={{ ml: 0.4, color: 'white' }} icon={<LogoutOutlined color="white" sx={{ ml: '12px !important' }} />} onClick={logout} variant="outlined" label="Logout" size="small" />
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                    </React.Fragment>
                )}
                <Box sx={{ overflow: 'auto' }}>
                    {globalAuthenticated && globalAuthenticated.contextLoaded && (globalAuthenticated.internalSubscription || globalAuthenticated.appFoundry || globalAuthenticated.bypass) && (
                        <React.Fragment>
                            <List
                                component={'nav'}
                                subheader={
                                    <ListSubheader component="div" id="management-subheader">
                                        Modules
                                    </ListSubheader>
                                }
                                sx={{ mb: 2 }}>
                                {/* <CXEvolutionSidebar /> */}
                                {isBCPActive && <CXAsCodeSidebar />}
                                {isGCAActive && <OrganizationSidebar />}
                            </List>
                            <Divider />
                            <List
                                component={'nav'}
                                subheader={
                                    <ListSubheader component="div" id="management-subheader">
                                        Common
                                    </ListSubheader>
                                }
                                sx={{ mb: 2 }}>
                                <ListItemButton component={RouterLink} to={`/jobs`} key={'Jobs'} selected={location.pathname.includes('jobs')}>
                                    <ListItemIcon>
                                        <WorkHistoryOutlined color="inherit" />
                                    </ListItemIcon>
                                    <Tooltip placement="top" title={jobStatus}>
                                        <ListItemText
                                            primary={
                                                <React.Fragment>
                                                    Jobs &nbsp; {jobCheckFetching ? <CloudSyncOutlined color="secondary" sx={{ verticalAlign: 'text-bottom' }} /> : <CloudQueueOutlined sx={{ verticalAlign: 'text-bottom' }} />} &nbsp;{' '}
                                                    {newJobsNotification ? (
                                                        <NotificationImportantOutlined
                                                            color="secondary"
                                                            sx={{ verticalAlign: 'text-bottom' }}
                                                            css={css`
                                                                animation: ${flickerStyle} ease-in-out 1s infinite alternate;
                                                            `}
                                                        />
                                                    ) : (
                                                        <React.Fragment></React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            }
                                        />
                                    </Tooltip>
                                </ListItemButton>
                                <ListItemButton component={RouterLink} to={`/orgConfiguration`} key={'OrgConfigurations'} selected={location.pathname.includes('orgConfigurations')}>
                                    <ListItemIcon>
                                        <SpeedOutlined color="inherit" />
                                    </ListItemIcon>
                                    <ListItemText primary="Organization Configuration" />
                                </ListItemButton>
                                {!globalAuthenticated.inGCloud && (
                                    <ListItemButton component={RouterLink} to={`/guide`} key={'Guide'} selected={location.pathname.includes('guide')}>
                                        <ListItemIcon>
                                            <QuizOutlined />
                                        </ListItemIcon>
                                        <ListItemText primary="Guide" />
                                    </ListItemButton>
                                )}
                                {globalAuthenticated.inGCloud && (
                                    <ListItemButton key={'Guide'} selected={location.pathname.includes('guide')} onClick={handleGuideDownload}>
                                        <ListItemIcon>
                                            <QuizOutlined />
                                        </ListItemIcon>
                                        <ListItemText primary="Guide" />
                                    </ListItemButton>
                                )}
                                <ListItemButton component={RouterLink} to={`/changelog`} key={'Changelog'} selected={location.pathname.includes('changelog')}>
                                    <ListItemIcon>
                                        <DifferenceOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Changelog" />
                                </ListItemButton>
                                {/* <ListItemButton component={RouterLink} to={`/feedback`} key={'Feedback'} selected={location.pathname.includes('feedback')}>
                                    <ListItemIcon>
                                        <TipsAndUpdatesOutlined color='inherit' />
                                    </ListItemIcon>
                                    <ListItemText primary="Feedback, Help" />
                                </ListItemButton> */}
                            </List>
                            <Divider />
                            <List
                                component={'nav'}
                                subheader={
                                    <ListSubheader component="div" id="admin-subheader">
                                        Administration
                                    </ListSubheader>
                                }
                                sx={{ mt: 2 }}>
                                <ListItemButton component={RouterLink} to={`/admin/audit/user`} key={'User-Audit'} selected={location.pathname.includes('admin/audit/user')}>
                                    <ListItemIcon>
                                        <VerifiedUserOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={'User Audit Logs'} />
                                </ListItemButton>
                                <ListItemButton component={RouterLink} to={`/admin/audit/org`} key={'Org-Audit'} selected={location.pathname.includes('admin/audit/org')}>
                                    <ListItemIcon>
                                        <VerifiedUserOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={'Org Audit Logs'} />
                                </ListItemButton>
                            </List>
                        </React.Fragment>
                    )}
                    {globalAuthenticated && globalAuthenticated.contextLoaded && globalAuthenticated.isAdmin && (
                        <React.Fragment>
                            <Divider />
                            <List
                                component={'nav'}
                                subheader={
                                    <ListSubheader component="div" id="admin-internal-subheader">
                                        Internal Administration
                                    </ListSubheader>
                                }
                                sx={{ mt: 2 }}>
                                <ListItemButton component={RouterLink} to={`/admin/accesslog`} key={'Access-Log'} selected={location.pathname.includes('admin/accesslog')}>
                                    <ListItemIcon>
                                        <ListAltOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={'Access Log'} />
                                </ListItemButton>
                                <ListItemButton component={RouterLink} to={`/internal/admins`} key={'Internal-Admin-Admins'} selected={location.pathname.includes('internal/admins')}>
                                    <ListItemIcon>
                                        <AdminPanelSettingsOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={'Admins'} />
                                </ListItemButton>
                                <ListItemButton component={RouterLink} to={`/internal/modules`} key={'Internal-Admin-Modules'} selected={location.pathname.includes('internal/modules')}>
                                    <ListItemIcon>
                                        <WidgetsOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={'Modules'} />
                                </ListItemButton>
                                <ListItemButton component={RouterLink} to={`/internal/subscriptions`} key={'Internal-Admin-Subscriptions'} selected={location.pathname.includes('internal/subscriptions')}>
                                    <ListItemIcon>
                                        <BalanceOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary={'Subscriptions'} />
                                </ListItemButton>
                            </List>
                        </React.Fragment>
                    )}
                </Box>
                <Box sx={{ mt: 'auto', pb: 1, mx: 'auto' }}>
                    <Typography variant="body2" textAlign="center">
                        {globalAuthenticated && globalAuthenticated.contextLoaded && (
                            <React.Fragment>
                                {org.name} <br />
                                {region} <br />
                                {/* {getEnvDisplay()} <br /> */}
                            </React.Fragment>
                        )}
                        Version: {getAppVersion()}
                        <br />
                        &copy; {moment ? moment().format('YYYY') : '2025'} Genesys
                    </Typography>
                </Box>
            </Drawer>
        </React.Fragment>
    );
};

export default Sidebar;

//example file of using fetchWrapper with retries and services setup.

import { fetchWithRetries, baseUri, fetchWrap } from 'common/utilities/fetchWrap';

/**
 * @description Authenticates the user to the backend for the api interactions between the frontend and genesys cloud.
 * @param {string} code genesys cloud authentication code (response type: code)
 * @param {string} env genesys cloud endpoint
 * @param {string} redirect_uri matching redirect from previous authentication
 * @return {*} No Content
 */
export const postAuthenticate = (code, env, redirect_uri) => {
    // Construct the data for the authentication request
    const queryStringData = {
        env: env,
        code: code,
        redirect: redirect_uri,
    };

    // Convert the data structure into encoded parameters.
    //const params = Object.keys(queryStringData).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryStringData[k])}`).join('&');
    const url = `${baseUri()}/sessions`;

    // Do not call this with retries, this should fail and not retry.
    return fetchWrap(
        url,
        {
            method: 'POST',
            body: JSON.stringify({
                ...queryStringData,
            }),
        },
        '',
        false,
        true
    );
};

/**
 * @description Deletes the current user session.
 * @return {Object}
 */
export const deleteAuthenticate = () => {
    const url = `${baseUri()}/sessions/me`;

    // Do not call this with retries, this should fail and not retry.
    return fetchWrap(
        url,
        {
            method: 'DELETE',
        },
        '',
        false,
        true
    );
};

/**
 * @description Gets the current users information for grants and permissions.
 * @return {Object}
 */
export const getUsersGrants = () => {
    const url = `${baseUri()}/authorization/subjects/me`;
    return fetchWithRetries(
        url,
        {
            method: 'GET',
        },
        '',
        false,
        true
    );
};

export default {
    postAuthenticate,
    deleteAuthenticate,
    getUsersGrants,
};

// private config
export const headerTitle = 'Genesys';

const provisioningInfo = {
    group: {
        name: headerTitle,
        description: 'To manage user for Genesys Operations Automation Tooling App',
        type: 'official',
        visibility: 'members',
        rulesVisible: true,
    },
};

const baseConfiguration = {
    app: {
        storageVersion: 'v1',
        solutionKey: 'genesys-ignite',
        solutionName: 'Genesys Operations Automation Tooling',
        bypassEnvs: ['local'],
        afAllowedEnvs: ['production'],
        internalAllowedEnvs: ['local', 'sandbox', 'test', 'production'],
    },
    client: '456d17b6-d1f9-4d26-8fb7-aac20fcc368e',
    clientCX: 'e59f91fe-14d1-4af6-9844-3daea1b52165',
    apiEndpoint: {
        local: 'https://sandbox.api.ignite.genesyspsdevops-dev.com',
        sandbox: 'https://sandbox.api.ignite.genesyspsdevops-dev.com',
        test: 'https://test.api.ignite.genesyspsdevops-dev.com',
        production: 'https://api.ignite.genesyspsapps.com',
    },
    provisioningInfo: provisioningInfo,
    appfoundryAppId: {
        local: ['premium-app-ignite-bcp'],
        test: ['premium-app-ignite-bcp'],
        sandbox: ['premium-app-ignite-bcp'],
        production: ['igniteBcp'],
    },
};

const local = {
    app: {
        ...baseConfiguration.app,
    },
    purecloud: {
        client: baseConfiguration.client,
        clientCX: baseConfiguration.clientCX,
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.local,
    provisioningInfo: provisioningInfo,
    appfoundryAppId: baseConfiguration.appfoundryAppId.local,
};

const test = {
    app: {
        ...baseConfiguration.app,
    },
    purecloud: {
        client: baseConfiguration.client,
        clientCX: baseConfiguration.clientCX,
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.test,
    provisioningInfo: provisioningInfo,
    appfoundryAppId: baseConfiguration.appfoundryAppId.test,
};

const sandbox = {
    app: {
        ...baseConfiguration.app,
    },
    purecloud: {
        client: baseConfiguration.client,
        clientCX: baseConfiguration.clientCX,
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.sandbox,
    provisioningInfo: provisioningInfo,
    appfoundryAppId: baseConfiguration.appfoundryAppId.sandbox,
};

const production = {
    app: {
        ...baseConfiguration.app,
    },
    purecloud: {
        client: baseConfiguration.client,
        clientCX: baseConfiguration.clientCX,
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.production,
    provisioningInfo: provisioningInfo,
    appfoundryAppId: baseConfiguration.appfoundryAppId.production,
};

const env = process.env.REACT_APP_CUSTOM_ENV && process.env.REACT_APP_CUSTOM_ENV.length > 0 ? process.env.REACT_APP_CUSTOM_ENV.trim() : '';
const build = process.env.REACT_APP_BUILD_VERSION && process.env.REACT_APP_BUILD_VERSION.length > 0 ? process.env.REACT_APP_BUILD_VERSION.trim() : '';
console.log('Found an issue, or need to report a bug. Please reach out to Genesys Cloud representative or support.');
console.log('Application Environment: %o', env);
console.log('Application Version: %o', env === 'local' ? 'local build' : build);
let config;
switch (env) {
    case 'production':
        config = production;
        break;
    case 'test':
        config = test;
        break;
    case 'sandbox':
        config = sandbox;
        break;
    case 'local':
        config = local;
        break;
    default:
        break;
}

export default config;

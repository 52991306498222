import { fetchWithRetries, baseUri, fetchWrap } from 'common/utilities/fetchWrap';

/**
 * @description Gets the current users activity across multiple organizations pk (email).
 * @return {Array}
 */
export const getActivityMe = () => {
    const url = `${baseUri()}/users/me/activity`;
    return fetchWithRetries(
        url,
        {
            method: 'GET',
        },
        '',
        false,
        true
    );
};

/**
 * @description Gets the user activity across multiple organizations. Fails if not admin
 * @return {Array}
 */
export const getAllActivity = () => {
    const url = `${baseUri()}/users/activity`;
    return fetchWithRetries(
        url,
        {
            method: 'GET',
        },
        '',
        false,
        true
    );
};

/**
 * @description Posts the activity for the current user session.
 * @return {Object}
 */
export const postActivity = () => {
    const url = `${baseUri()}/users/me/activity`;

    // Do not call this with retries, this should fail and not retry.
    return fetchWrap(
        url,
        {
            method: 'POST',
            body: JSON.stringify({}),
        },
        '',
        false,
        true
    );
};

export default {
    getActivityMe,
    getAllActivity,
    postActivity,
};
